export default function BioBar() {
  return (
    <>
      <div className="biobar-container">
        <h1 className="biobar-headline">David Schwertgen</h1>
        <h3 className="biobar-small-font">Web Developer</h3>
      </div>
    </>
  );
}
