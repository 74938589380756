import BioBar from "../componentes/BioBar";
import FontContainer from "../componentes/FontContainer";

export default function Main() {
  return (
    <>
      <FontContainer />
      <BioBar />
    </>
  );
}
