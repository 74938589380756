export default function SideBar() {
  return (
    <>
      <div className="sidebar-container">
        <a
          href="https://firefish.social/@subguy"
          target="_blank"
          rel="noreferrer"
          className="sidebar-links"
        >
          firefish
        </a>
        <a
          href="https://bsky.app/profile/subguy.bsky.social"
          target="_blank"
          rel="noreferrer"
          className="sidebar-links"
        >
          bluesky
        </a>
        <a
          href="https://github.com/SubliminalGuy"
          target="_blank"
          rel="noreferrer"
          className="sidebar-links"
        >
          github
        </a>
        <a
          href="https://www.linkedin.com/in/david-schwertgen-505044107"
          target="_blank"
          rel="noreferrer"
          className="sidebar-links"
        >
          linkedin
        </a>
      </div>
    </>
  );
}
